<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      :dark="darkTheme"
      :width="menuWidth"
      :color="sideBackColor"
    >
      <v-toolbar
        :color="logoBackColor"
        :dark="darkTheme"
        :prominent="prominentLogo"
        class="elevation-1"
      >
        <v-img :src="mainLogo" contain height="90%" />
      </v-toolbar>
      <perfect-scrollbar>
        <v-list dense expand :dark="darkTheme">
          <template v-for="(item, i) in menus">
            <v-list-group
              v-if="item.items"
              v-show="hasPermission(item.name)"
              :key="i + item.name"
              :prepend-icon="item.icon"
              :group="item.group"
              no-action
              :active-class="darkTheme ? 'white--text' : ''"
            >
              <template v-slot:activator>
                <v-list-item-title class="text-capitalize">
                  {{ item.title }}
                </v-list-item-title>
              </template>
              <template v-for="(subItem, j) in item.items">
                <v-list-item
                  :key="j"
                  :disabled="subItem.disabled"
                  :to="genChildTarget(item, subItem)"
                  :active-class="darkTheme ? 'white--text' : ''"
                  class="pl-12"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-capitalize">{{
                      subItem.title
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon :class="[subItem.actionClass || 'success--text']">
                      {{ subItem.action }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-group>
            <v-subheader v-else-if="item.header" :key="i"
              >{{ item.header }}
            </v-subheader>
            <v-divider v-else-if="item.divider" :key="i" />
            <!-- Top Level-->
            <v-list-item
              v-else-if="!item.items"
              v-show="hasPermission(item.name)"
              :key="item.name"
              :disabled="item.disabled"
              :href="item.href"
              :target="item.target"
              :to="!item.href ? { name: item.name } : null"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-capitalize"
                >{{ item.title }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-else
              v-show="hasPermission(item.name)"
              :key="item.name"
              :disabled="item.disabled"
              :href="item.href"
              :target="item.target"
              :to="!item.href ? { name: item.name } : null"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-capitalize"
                >{{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </perfect-scrollbar>
    </v-navigation-drawer>
    <v-app-bar app :dark="appBarDark" :color="appBarColor">
      <v-btn icon color="primary" @click.stop="drawer = !drawer">
        <v-icon
          >{{ drawer ? "mdi-chevron-double-left" : "mdi-chevron-double-right" }}
        </v-icon>
      </v-btn>
      <!--            <v-toolbar-title-->
      <!--                    style="width: 300px"-->
      <!--                    class="ml-0 pl-4">-->
      <!--                <span class="hidden-sm-and-down">Plantilla Vuetify</span>-->
      <!--            </v-toolbar-title>-->
      <v-spacer />
      <v-toolbar-items>
        <v-menu
          v-model="userDropdown"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          ripple="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="elevation-0 text-capitalize"
              color="primary"
              text
              v-on="on"
            >
              <v-avatar color="primary" size="36" class="mr-2"> </v-avatar>
              {{ user.name }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="primary darken-2">
              <v-list dense class="primary darken-2">
                <v-list-item>
                  <v-list-item-avatar color="primary"> </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="white--text"
                      >{{ user.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-title>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-settings-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'app' }">
                <v-list-item-icon>
                  <v-icon>mdi-apps</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Aplicaciones</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasPermission('users')"
                :to="{ name: 'users' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon dark color="red">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <div class="page-wrapper">
        <v-toolbar flat color="transparent" dense>
          <v-toolbar-items>
            <v-breadcrumbs class="text-capitalize" :items="crumbs">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
          </v-toolbar-items>
        </v-toolbar>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </div>
    </v-main>
  </div>
</template>

<script>
import menu from "@/utils/menu";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {},
  data: () => ({
    userDropdown: false,
    drawer: null,
    menus: menu,
    menuWidth: 300, // Ancho de la barra lateral principal
    mainLogo: require("@/assets/branding/logo_letras.svg"), // Este es el logo que aparece en la barra lateral
    prominentLogo: true, // Logo pequeño o Grande
    logoBackColor: "primary", // Color de fondo para el logo de la barra lateral
    sideBackColor: "primary darken-1", // Color de fondo de la barra lateral
    darkTheme: true, // Tema obscuro ( letras en blanco en caso de colores obscuros de la barra lateral)
    appBarColor: "", // Color de la barra principal de la app
    appBarDark: false, // Tema obscuro ( letras en blanco en caso de colores obscuros de la barra principal)
  }),
  computed: {
    ...mapGetters(["user"]),
    version() {
      return process.env.VUE_APP_VERSIONP;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    crumbs: function () {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          // to: breadcrumbArray[idx - 1]
          //     ? "/" + breadcrumbArray[idx - 1].path + "/" + path
          //     : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path,
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
  },
  methods: {
    genChildTarget(item, subItem) {
      if (subItem.href) return;
      if (subItem.component) {
        return {
          name: subItem.component,
        };
      }
      return { name: `${item.group}/${subItem.name}` };
    },
    hasPermission(view) {
      return this.user.permissions.some((e) => e.name === view);
    },
    goDash() {
      this.$router.push({ name: "app" });
    },
    logout() {
      this.$http
        .post("logout")
        .then(() => {})
        .finally(() => {
          this.$store.dispatch("reset");
          this.$router.push({ name: "login" });
          this.$store.dispatch("working", false);
        });
    },
  },
};
</script>
