const Menu = [
  { header: "Ventas" },
  {
    title: "Facturación",
    icon: "mdi-menu-right",
    group: "ventas",
    name: "facturacion",
    items: [
      {
        title: "Diaria",
        group: "ventas",
        component: "factDiaria",
      },
      {
        title: "Mensual (Backorder)",
        group: "ventas",
        component: "factMensual",
      },
      {
        title: "Anual",
        group: "ventas",
        component: "factAnual",
      },
    ],
  },
  {
    title: "usersPerformance",
    group: "usersPerformance",
    name: "usersPerformance",
    icon: "mdi-menu-right",
  },
  {
    title: "Producción",
    group: "produccion",
    name: "produccion",
    icon: "mdi-menu-right",
  },
  {
    title: "Productos",
    group: "productos",
    name: "productos",
    icon: "mdi-menu-right",
  },
  {
    title: "Pedidos",
    group: "pedidos",
    name: "pedidos",
    icon: "mdi-menu-right",
  },
  { header: "Indicadores de eficiencia" },
  {
    title: "Efectividad de producción",
    group: "calidad",
    name: "efectividadProduccion",
    icon: "mdi-menu-right",
  },
  {
    title: "Quejas de cliente sobre calidad",
    group: "calidad",
    name: "quejasCliente",
    icon: "mdi-menu-right",
  },
  { header: "Recursos Humanos" },
  {
    title: "Personal",
    group: "recursosHumanos",
    name: "personal",
    icon: "mdi-menu-right",
  },
  {
    title: "Ausencias",
    group: "recursosHumanos",
    name: "faltas",
    icon: "mdi-menu-right",
  },
  {
    title: "Accidentes",
    group: "accidentes",
    name: "accidentes",
    icon: "mdi-menu-right",
  },
];

export default Menu;
